import React from 'react'

import { FooterStyle } from './Footer.style'
function Footer() {
  let phone1 = 2296
  let phone2 = 6016
  let email1 = "philip"
  let email2 = "@kode4.dk"
  return (
    
      <FooterStyle>
                <p id="elpriser">
          <a href="https://www.elprisenligenu.dk">
            <img src="https://ik.imagekit.io/ajdfkwyt/hva-koster-strommen/elpriser-leveret-af-elprisenligenu_LJNbbujZAX.png" alt="Elpriser leveret af Elprisen lige nu.dk" width="200" height="45"/>
            </a>
            </p>
        <div id='loveDiv'>
        <p>Kodet med kærlighed i Aalborg ❤️</p> 
        </div>
        <div id='copyrightDiv'>
          <p>Copyright © {new Date().getFullYear()} Philip Thomsen</p>
        
           
        </div>
        <div id='contactInfo'>
          <p><a href={`mailto:${email1}${email2}`}>📧</a></p>
          <p><a href={`tel:${phone1}${phone2}`}>☎️</a></p>
          <p><a href="https://kode4.dk">🌐</a></p>
        </div>
        </FooterStyle>
    
  )
}

export default Footer